import React, { useEffect, useState } from 'react';
import './NewScV.css';
import 'antd/dist/antd.css';

import HeaderBlock from './HeaderBlock/HeaderBlock';
import BodyBlock from './bodyBlock/BodyBlock';
import NavBar from '../common/navigation-bar/navigation-bar';

import { Example2, ModuleData2 } from '../utils/temporary';
import { findDefM, titleHeader } from '../utils/datas';
import '../common/commonStyles.css';

const NewScV = () => {
    /* Data */
    const [dataNav] = useState(Example2[0]);
    const [iconDatas] = useState(ModuleData2);
    /* Menu settings */
    const [collapse, setCollapse] = useState(dataNav?.collapseMenu || false);
    const [subItem, setSubItem] = useState(titleHeader(dataNav?.data, findDefM(dataNav?.data)?.title || ''));

    /* Other settings */
    const [collapseHeader, setCollapseHeader] = useState(false);
    const [clickMenu, setClickMenu] = useState();
    const [linkType, setLinkType] = useState();


    useEffect(() => {
        const defaultModule = findDefM(dataNav?.data);
        if (defaultModule) {
            setLinkType(defaultModule.linkType);
            setClickMenu([defaultModule.title, defaultModule.module]);
            setSubItem([dataNav?.defaultSelectedKeys, dataNav?.defaultOpenKeys]);
        }
    }, [dataNav]);

    return (
        <div className="NewScV_wrap">
            <div className={`NewScV_header ${collapseHeader ? 'active' : ''}`}>
                <HeaderBlock setCollapseHeader={setCollapseHeader} collapseHeader={collapseHeader} />
                {collapseHeader && <span className="click_area_nav" onClick={() => setCollapseHeader(!collapseHeader)} />}
            </div>
            <NavBar
                setClickMenu={setClickMenu}
                clickMenu={clickMenu}
                dataNav={dataNav?.data}
                iconData={iconDatas}
                collapse={collapse}
                setCollapse={setCollapse}
                subItem={subItem}
                setSubItem={setSubItem}
                setLinkType={setLinkType}
            />
            <BodyBlock clickMenu={clickMenu} subItem={subItem} linkType={linkType} dataNav={dataNav?.data} />
            <div className={`NewScV_footer `}/>
        </div>
    );
};

export default NewScV;
