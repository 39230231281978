// BodyBlock.js

import React from 'react';
import './BodyBlock.css';
import DOMPurify from 'dompurify';

const BodyBlock = ({ clickMenu }) => {
    const allowedUrls = ['/sxodim/', '/msb/', '/budget/', '/public-transport/']; // Пример списка разрешённых путей

    const isValidUrl = (url) => {
        return allowedUrls.includes(url);
    };

    const sanitizedTitle = DOMPurify.sanitize(clickMenu && clickMenu[0]);

    return (
        <div className="BodyBlock_wrap">
            <div className="titleBlockIframe noCopy">
                <span>{sanitizedTitle}</span>
            </div>

            {clickMenu?.length && isValidUrl(clickMenu[1]) ? (
                <iframe
                    src={clickMenu[1]}
                    width="100%"
                    height="100%"
                    align="left"
                    title={`iframe-${sanitizedTitle}`}
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    allow="geolocation; microphone; camera"
                />
            ) : (
                <div className="iframe_placeholder">
                    <span>
                        Контент временно недоступен или URL недействителен. Пожалуйста,
                        попробуйте позже.
                    </span>
                </div>
            )}
        </div>
    );
};

export default BodyBlock;
